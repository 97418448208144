@import '_variables';

html {
	font-family: 'Roboto', sans-serif;
	line-height: 1.5;
}
code,
pre {
	font-family: courier new, Courier, monospace;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
	color: var(--base90);
	max-width: 50rem;
}
h1 {
	font-size: $b3;
	font-weight: bold;
	line-height: 1.05;
	margin-bottom: 1.7239rem;
}
h2 {
	font-size: $a2;
	font-weight: bold;
	line-height: 1.15;
	margin: 2.1393rem 0 1.5rem;
}
h3 {
	font-size: $b2;
	font-weight: bold;
	line-height: 1.25;
	margin: 1.86375rem 0 1rem;
}
h4 {
	font-size: $a1;
	font-weight: bold;
	margin: 1.5rem 0 1rem;
}
h5 {
	font-size: $a1;
	font-weight: bold;
	margin-top: 1.5rem;
}
p {
	color: var(--base90);
	font-size: $a1;
	margin-bottom: m(2/3);
}
a {
	color: var(--accent50);
}
a:visited {
	color: var(--accent70);
}
a:hover {
	color: var(--accent40);
}
a:visited:hover {
	color: var(--accent55);
}
li {
	max-width: 50rem;
	color: var(--base90);
}
hr {
	border: 0;
	border-top: 1px solid #ccc;
	width: 50rem;
	margin: 1rem 0;
}
#ptr,
#ptr-app {
	@include positionFill;
}

//.ptr-light {
//	--base0: #{$gray0};
//	--base0rgb: #{$gray0rgb};
//	--base1: #{$gray1};
//	--base2: #{$gray2};
//	--base3: #{$gray3};
//	--base4: #{$gray4};
//	--base5: #{$gray5};
//	--base6: #{$gray6};
//	--base7: #{$gray7};
//	--base8: #{$gray8};
//	--base9: #{$gray9};
//	--base10: #{$gray10};
//	--base11: #{$gray11};
//	--base12: #{$gray12};
//	--base12rgb: #{$gray12rgb};
//
//	--accent0: #{$accent0};
//	--accent1: #{$accent1};
//	--accent2: #{$accent2};
//	--accent3: #{$accent3};
//	--accent4: #{$accent4};
//	--accent5: #{$accent5};
//	--accent6rgb: #{$accent6rgb};
//	--accent6: #{$accent6};
//	--accent7: #{$accent7};
//	--accent8: #{$accent8};
//	--accent9: #{$accent9};
//	--accent10: #{$accent10};
//	--accent11: #{$accent11};
//	--accent12: #{$accent12};
//}
//.ptr-dark {
//	--base0: #{$darkmode-gray12};
//	--base0rgb: #{$darkmode-gray12rgb};
//	--base1: #{$darkmode-gray11};
//	--base2: #{$darkmode-gray10};
//	--base3: #{$darkmode-gray9};
//	--base4: #{$darkmode-gray8};
//	--base5: #{$darkmode-gray7};
//	--base6: #{$darkmode-gray6};
//	--base7: #{$darkmode-gray5};
//	--base8: #{$darkmode-gray4};
//	--base9: #{$darkmode-gray3};
//	--base10: #{$darkmode-gray2};
//	--base11: #{$darkmode-gray1};
//	--base12: #{$darkmode-gray0};
//	--base10rgb: #{$darkmode-gray0rgb};
//
//	--accent0: #{$darkmode-accent12};
//	--accent1: #{$darkmode-accent11};
//	--accent2: #{$darkmode-accent10};
//	--accent3: #{$darkmode-accent9};
//	--accent4: #{$darkmode-accent8};
//	--accent5: #{$darkmode-accent7};
//	--accent6rgb: #{$darkmode-accent6rgb};
//	--accent6: #{$darkmode-accent6};
//	--accent7: #{$darkmode-accent5};
//	--accent8: #{$darkmode-accent4};
//	--accent9: #{$darkmode-accent3};
//	--accent10: #{$darkmode-accent2};
//	--accent11: #{$darkmode-accent1};
//	--accent12: #{$darkmode-accent0};
//}

.ptr-light {
	--base0: #{$lightBase0};
	--base0rgb: #{$lightBase0rgb};
	--base05: #{$lightBase05};
	--base10: #{$lightBase10};
	--base15: #{$lightBase15};
	--base20: #{$lightBase20};
	--base25: #{$lightBase25};
	--base30: #{$lightBase30};
	--base35: #{$lightBase35};
	--base40: #{$lightBase40};
	--base45: #{$lightBase45};
	--base50: #{$lightBase50};
	--base50rgb: #{$lightBase50rgb};
	--base55: #{$lightBase55};
	--base60: #{$lightBase60};
	--base65: #{$lightBase65};
	--base70: #{$lightBase70};
	--base75: #{$lightBase75};
	--base80: #{$lightBase80};
	--base85: #{$lightBase85};
	--base90: #{$lightBase90};
	--base95: #{$lightBase95};
	--base100: #{$lightBase100};
	--base100rgb: #{$lightBase100rgb};

	--accent0: #{$lightAccent0};
	--accent0rgb: #{$lightAccent0rgb};
	--accent05: #{$lightAccent05};
	--accent10: #{$lightAccent10};
	--accent15: #{$lightAccent15};
	--accent20: #{$lightAccent20};
	--accent25: #{$lightAccent25};
	--accent30: #{$lightAccent30};
	--accent35: #{$lightAccent35};
	--accent40: #{$lightAccent40};
	--accent45: #{$lightAccent45};
	--accent50: #{$lightAccent50};
	--accent50rgb: #{$lightAccent50rgb};
	--accent55: #{$lightAccent55};
	--accent60: #{$lightAccent60};
	--accent65: #{$lightAccent65};
	--accent70: #{$lightAccent70};
	--accent75: #{$lightAccent75};
	--accent80: #{$lightAccent80};
	--accent85: #{$lightAccent85};
	--accent90: #{$lightAccent90};
	--accent95: #{$lightAccent95};
	--accent100: #{$lightAccent100};
	--accent100rgb: #{$lightAccent100rgb};
}

.ptr-dark {
	--base0: #{$darkBase0};
	--base0rgb: #{$darkBase0rgb};
	--base05: #{$darkBase05};
	--base10: #{$darkBase10};
	--base15: #{$darkBase15};
	--base20: #{$darkBase20};
	--base25: #{$darkBase25};
	--base30: #{$darkBase30};
	--base35: #{$darkBase35};
	--base40: #{$darkBase40};
	--base45: #{$darkBase45};
	--base50: #{$darkBase50};
	--base50rgb: #{$darkBase50rgb};
	--base55: #{$darkBase55};
	--base60: #{$darkBase60};
	--base65: #{$darkBase65};
	--base70: #{$darkBase70};
	--base75: #{$darkBase75};
	--base80: #{$darkBase80};
	--base85: #{$darkBase85};
	--base90: #{$darkBase90};
	--base95: #{$darkBase95};
	--base100: #{$darkBase100};
	--base100rgb: #{$darkBase100rgb};

	--accent0: #{$darkAccent0};
	--accent0rgb: #{$darkAccent0rgb};
	--accent05: #{$darkAccent05};
	--accent10: #{$darkAccent10};
	--accent15: #{$darkAccent15};
	--accent20: #{$darkAccent20};
	--accent25: #{$darkAccent25};
	--accent30: #{$darkAccent30};
	--accent35: #{$darkAccent35};
	--accent40: #{$darkAccent40};
	--accent45: #{$darkAccent45};
	--accent50: #{$darkAccent50};
	--accent50rgb: #{$darkAccent50rgb};
	--accent55: #{$darkAccent55};
	--accent60: #{$darkAccent60};
	--accent65: #{$darkAccent65};
	--accent70: #{$darkAccent70};
	--accent75: #{$darkAccent75};
	--accent80: #{$darkAccent80};
	--accent85: #{$darkAccent85};
	--accent90: #{$darkAccent90};
	--accent95: #{$darkAccent95};
	--accent100: #{$darkAccent100};
	--accent100rgb: #{$darkAccent100rgb};
}

.ptr-bo-colours {
	--base0: #{$bo0};
	--base0rgb: #{$bo0rgb};
	--base1: #{$bo1};
	--base2: #{$bo2};
	--base3: #{$bo3};
	--base4: #{$bo4};
	--base5: #{$bo5};
	--base6: #{$bo6};
	--base7: #{$bo7};
	--base8: #{$bo8};
	--base9: #{$bo9};
	--base10: #{$bo10};
	--base11: #{$bo11};
	--base12: #{$bo12};
	--base12rgb: #{$bo12rgb};

	--accent0: #{$bo-accent0};
	--accent1: #{$bo-accent1};
	--accent2: #{$bo-accent2};
	--accent3: #{$bo-accent3};
	--accent4: #{$bo-accent4};
	--accent5: #{$bo-accent5};
	--accent6rgb: #{$bo-accent6rgb};
	--accent6: #{$bo-accent6};
	--accent7: #{$bo-accent7};
	--accent8: #{$bo-accent8};
	--accent9: #{$bo-accent9};
	--accent10: #{$bo-accent10};
	--accent11: #{$bo-accent11};
	--accent12: #{$bo-accent12};
}
