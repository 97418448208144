// measure unit - use whole numbers or thirds if neccessary
@function m($amount) {
	@return $amount * 1.5rem;
}

// fill container with position context
@mixin positionFill() {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

// center in container with position context
@mixin positionCenter() {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
@mixin positionCenterVertical() {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}
@mixin positionCenterHorizontal() {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

// font sizes
$a0: 0.618rem;
$a1: 1rem;
$a2: 1.618rem;
$a3: 2.618rem;
$a4: 4.236rem;
$a5: 6.854rem;
$a6: 11.089rem;
$a7: 17.942rem;

$b0: 0.5rem;
$b1: 0.809rem;
$b2: 1.309rem;
$b3: 2.118rem;
$b4: 3.427rem;
$b5: 5.545rem;
$b6: 8.971rem;
$b7: 14.515rem;

// colours
$gray0: #fffefc;
$gray0rgb: 255, 254, 252;
$gray1: #f4f3f1;
$gray2: #e8e7e5;
$gray3: #dad9d8;
$gray4: #c1c1bf;
$gray5: #a4a3a2;
$gray6: #858483;
$gray7: #6c6b6a;
$gray8: #5b5a5a;
$gray9: #484847;
$gray10: #323231;
$gray11: #1d1d1c;
$gray12: #040300;
$gray12rgb: 4, 3, 0;

$darkmode-gray0: #fffefc;
$darkmode-gray0rgb: 255, 254, 252;
$darkmode-gray1: #f4f3f1;
$darkmode-gray2: #e8e7e5;
$darkmode-gray3: #dad9d8;
$darkmode-gray4: #c1c1bf;
$darkmode-gray5: #9f9e9d;
$darkmode-gray6: #858483;
$darkmode-gray7: #676665;
$darkmode-gray8: #565655;
$darkmode-gray9: #434343;
$darkmode-gray10: #2b2b2b;
$darkmode-gray11: #1d1d1c;
$darkmode-gray12: #040300;
$darkmode-gray12rgb: 4, 3, 0;

$accent0: #fffefc;
$accent1: #eaf5f2;
$accent2: #d5ece9;
$accent3: #bae1dd;
$accent4: #87cdc8;
$accent5: #37b4af;
$accent6rgb: 42, 146, 142;
$accent6: #2a928e;
$accent7: #287773;
$accent8: #266460;
$accent9: #224f4c;
$accent10: #1c3633;
$accent11: #141f1d;
$accent12: #040300;

$darkmode-accent0: #fffefc;
$darkmode-accent1: #eaf5f2;
$darkmode-accent2: #d5ece9;
$darkmode-accent3: #bae1dd;
$darkmode-accent4: #87cdc8;
$darkmode-accent5: #2aafaa;
$darkmode-accent6rgb: 42, 146, 142;
$darkmode-accent6: #2a928e;
$darkmode-accent7: #28716d;
$darkmode-accent8: #255e5b;
$darkmode-accent9: #214a47;
$darkmode-accent10: #1a2f2c;
$darkmode-accent11: #141f1d;
$darkmode-accent12: #040300;

$lightBase0: hsl(40, 100%, 99.41%);
$lightBase0rgb: 255, 254, 252;
$lightBase05: hsl(40.13, 17.97%, 96.74%);
$lightBase10: hsl(40.24, 10.87%, 94.62%);
$lightBase15: hsl(40.35, 7.49%, 92.23%);
$lightBase20: hsl(40.51, 5.28%, 89.04%);
$lightBase25: hsl(40.72, 3.78%, 84.8%);
$lightBase30: hsl(40.99, 2.78%, 79.5%);
$lightBase35: hsl(41.31, 2.1%, 73.27%);
$lightBase40: hsl(41.66, 1.65%, 66.4%);
$lightBase45: hsl(42.04, 1.34%, 59.24%);
$lightBase50: hsl(42.42, 1.12%, 52.14%);
$lightBase50rgb: 134, 134, 132;
$lightBase55: hsl(42.79, 1.15%, 45.45%);
$lightBase60: hsl(43.13, 1.3%, 39.43%);
$lightBase65: hsl(43.44, 1.46%, 34.24%);
$lightBase70: hsl(43.71, 1.64%, 29.91%);
$lightBase75: hsl(43.93, 1.83%, 26.33%);
$lightBase80: hsl(44.14, 2.03%, 23.2%);
$lightBase85: hsl(44.36, 2.3%, 20.09%);
$lightBase90: hsl(44.63, 2.74%, 16.32%);
$lightBase95: hsl(45.06, 3.82%, 11.13%);
$lightBase100: hsl(45, 100%, 0.78%);
$lightBase100rgb: 4, 3, 0;

$darkBase0: hsl(45, 100%, 0.78%);
$darkBase0rgb: 4, 3, 0;
$darkBase05: hsl(36.11, 1.69%, 7.43%);
$darkBase10: hsl(37.31, 1.06%, 11.31%);
$darkBase15: hsl(38.14, 0.85%, 15.16%);
$darkBase20: hsl(38.73, 0.75%, 19.42%);
$darkBase25: hsl(39.12, 0.69%, 24.1%);
$darkBase30: hsl(39.39, 0.66%, 29.18%);
$darkBase35: hsl(39.57, 0.64%, 34.59%);
$darkBase40: hsl(39.69, 0.62%, 40.23%);
$darkBase45: hsl(39.77, 0.61%, 45.94%);
$darkBase50: hsl(39.83, 0.65%, 51.62%);
$darkBase50rgb: 132, 132, 131;
$darkBase55: hsl(39.87, 0.81%, 57.18%);
$darkBase60: hsl(39.9, 1%, 62.55%);
$darkBase65: hsl(39.93, 1.26%, 67.73%);
$darkBase70: hsl(39.94, 1.59%, 72.74%);
$darkBase75: hsl(39.96, 2.06%, 77.6%);
$darkBase80: hsl(39.97, 2.78%, 82.36%);
$darkBase85: hsl(39.98, 4%, 87.06%);
$darkBase90: hsl(39.99, 6.49%, 91.63%);
$darkBase95: hsl(40, 13.73%, 95.87%);
$darkBase100: hsl(40, 100%, 99.41%);
$darkBase100rgb: 255, 254, 252;

$lightAccent0: hsl(40, 100%, 99.41%);
$lightAccent0rgb: 255, 254, 252;
$lightAccent05: hsl(156.45, 33.56%, 96.31%);
$lightAccent10: hsl(166.02, 36.8%, 93.53%);
$lightAccent15: hsl(169.78, 38.24%, 90.37%);
$lightAccent20: hsl(172.12, 39.2%, 86.1%);
$lightAccent25: hsl(173.77, 39.96%, 80.31%);
$lightAccent30: hsl(175.04, 40.71%, 72.85%);
$lightAccent35: hsl(176.12, 41.77%, 63.58%);
$lightAccent40: hsl(177.17, 43.99%, 51.82%);
$lightAccent45: hsl(177.69, 59.73%, 41.29%);
$lightAccent50: hsl(177.45, 55.5%, 37.31%);
$lightAccent50rgb: 42, 148, 143;
$lightAccent55: hsl(177.15, 51.51%, 33.37%);
$lightAccent60: hsl(176.8, 47.79%, 29.66%);
$lightAccent65: hsl(176.39, 44.39%, 26.34%);
$lightAccent70: hsl(175.94, 41.34%, 23.48%);
$lightAccent75: hsl(175.44, 38.61%, 21.05%);
$lightAccent80: hsl(174.86, 36.01%, 18.89%);
$lightAccent85: hsl(174.08, 33.12%, 16.68%);
$lightAccent90: hsl(172.64, 29.1%, 13.94%);
$lightAccent95: hsl(168.3, 21.77%, 10.02%);
$lightAccent100: hsl(45, 100%, 0.78%);
$lightAccent100rgb: 4, 3, 0;

$darkAccent0: hsl(45, 100%, 0.78%);
$darkAccent0rgb: 4, 3, 0;
$darkAccent05: hsl(167.35, 20.67%, 9.54%);
$darkAccent10: hsl(171.48, 26.63%, 12.47%);
$darkAccent15: hsl(172.9, 29.73%, 14.34%);
$darkAccent20: hsl(173.85, 32.38%, 16.15%);
$darkAccent25: hsl(174.72, 35.42%, 18.43%);
$darkAccent30: hsl(175.51, 38.96%, 21.36%);
$darkAccent35: hsl(176.18, 42.87%, 24.9%);
$darkAccent40: hsl(176.7, 46.92%, 28.8%);
$darkAccent45: hsl(177.1, 50.91%, 32.77%);
$darkAccent50: hsl(177.39, 54.65%, 36.48%);
$darkAccent50rgb: 42, 144, 139;
$darkAccent55: hsl(177.6, 57.99%, 39.69%);
$darkAccent60: hsl(177.75, 60.79%, 42.24%);
$darkAccent65: hsl(177.46, 48.92%, 47.96%);
$darkAccent70: hsl(177.06, 43.66%, 53.13%);
$darkAccent75: hsl(176.78, 42.93%, 56.5%);
$darkAccent80: hsl(176.5, 42.38%, 59.58%);
$darkAccent85: hsl(176.09, 41.74%, 63.82%);
$darkAccent90: hsl(175.33, 40.94%, 70.61%);
$darkAccent95: hsl(173.52, 39.83%, 81.44%);
$darkAccent100: hsl(40, 100%, 99.41%);
$darkAccent100rgb: 255, 254, 252;

$bo0: #c7c5c2;
$bo0rgb: 199, 197, 194;
$bo1: #bebcb9;
$bo2: #b6b4b1;
$bo3: #aba9a6;
$bo4: #989694;
$bo5: #81807d;
$bo6: #6a6866;
$bo7: #565553;
$bo8: #494846;
$bo9: #3b3a38;
$bo10: #2a2927;
$bo11: #191917;
$bo12: #040300;
$bo12rgb: 4, 3, 0;

$bo-lighter1: #d8d6d4;
$bo-lighter2: #e3e2e1;
$bo-lighter3: #eeeeed;

$bo-accent0: #c7c5c2;
$bo-accent1: #b9beba;
$bo-accent2: #abb7b3;
$bo-accent3: #9aaeaa;
$bo-accent4: #7b9d99;
$bo-accent5: #528a85;
$bo-accent6rgb: 34, 116, 112;
$bo-accent6: #227470;
$bo-accent7: #215e5b;
$bo-accent8: #1f504d;
$bo-accent9: #1c403d;
$bo-accent10: #182d2a;
$bo-accent11: #121b19;
$bo-accent12: #040300;

$bo-accent-lighter1: #aecbc8;
$bo-accent-lighter2: #c1d7d5;
$bo-accent-lighter3: #d3e3e2;
